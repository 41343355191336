var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2d5e1de26b1463655311b19d612451116b05c656"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BUILD_LEVEL } from './config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  environment: BUILD_LEVEL,
  dsn: SENTRY_DSN || "https://fb230d215bd745d6a8152f6145c8a409@o206088.ingest.sentry.io/1324566",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  normalizeDepth: 5,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
