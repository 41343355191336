import moment from "moment";
import { Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";

export const nearestMinutes = (interval, someMoment) => {
  const roundedMinutes =
    Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
};

const BetterTimePicker = ({
  onChange,
  from,
  size,
  placeholder,
  allowClear,
  defaultValue,
  noInitialValue,
  className,
  minuteStep,
  value,
}) => {
  const stepCount = (24 * 60) / minuteStep;

  const times = [];
  for (let i = 0; i < stepCount; i += 1) {
    const defaultMoment = value ? value.clone() : moment();
    times.push(
      defaultMoment
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add(minuteStep * i, "minutes")
    );
  }

  const getMatchingTime = () => {
    const time = value || moment();
    for (let i = 0; i < stepCount; i += 1) {
      if (time <= times[i]) {
        return i - 1;
      }
    }
  };

  const matchTimeString = (typed) => {
    for (let i = 0; i < stepCount; i += 1) {
      if (times[i].format("HH:mm").slice(0, typed.length) === typed) {
        return i;
      }
    }
    return null;
  };

  const timeString = (a, b) => {
    const hourDiff = a.diff(b, "hours");
    if (hourDiff < 1) {
      return `(${moment.duration(a.diff(b)).format("mm")} mins)`;
    } else {
      return `(${moment.duration(a.diff(b)).format("H")} hrs)`;
    }
  };

  const dropdownEl = useRef(null);

  const [selected, setSelected] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (dropdownEl.current && selected) {
      const { scrollHeight } = dropdownEl.current;

      dropdownEl.current.scrollTop =
        (((selected - 2) * 1.0) / stepCount) * scrollHeight;
    }
  }, [selected, dropdownOpen]);

  const [typed, setTyped] = useState(
    (value && moment(value).format("HH:mm")) || ""
  );

  useEffect(() => {
    if (defaultValue && !value) {
      setTyped(moment(defaultValue).format("HH:mm"));
    } else if (value) {
      setTyped(moment(value).format("HH:mm"));
    } else if (!noInitialValue) {
      setTyped(nearestMinutes(minuteStep, moment()).format("HH:mm"));
    }
  }, [value, defaultValue]);

  useEffect(() => {
    if (typed && matchTimeString(typed)) {
      setSelected(matchTimeString(typed));
    }
  }, [typed]);

  useEffect(() => {
    if (dropdownEl.current && !noInitialValue) {
      const matching = getMatchingTime();
      if (matching > 0) {
        setSelected(matching);
      }
    }
  }, [dropdownOpen, dropdownEl]);

  const inputEl = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (selected < stepCount) {
        setTyped(times[selected + 1]?.format("HH:mm"));
        setSelected(selected + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (selected > 0) {
        setTyped(times[selected - 1]?.format("HH:mm"));
        setSelected(selected - 1);
      }
    } else if (e.key === "Enter") {
      if (onChange) {
        if (times[selected]?.format("HH:mm") !== typed) {
          onChange(moment(typed, "HH:mm"));
        } else {
          onChange(times[selected]);
        }
      }
      inputEl.current.blur();
    }
  };

  return (
    <div className={`relative inline-block text-left ${className}`}>
      <div className="flex space-x-1 items-center">
        <Input
          onBlur={() => {
            if (times[selected] !== value && onChange && selected !== null) {
              onChange(times[selected]);
            }
            setTimeout(() => {
              setDropdownOpen(false);
            }, 300);
          }}
          size={size}
          onKeyDown={handleKeyDown}
          onFocus={() => setDropdownOpen(true)}
          placeholder={placeholder || "Time"}
          ref={inputEl}
          className="w-24"
          id="options-menu"
          value={typed}
          onChange={(e) => setTyped(e.target.value)}
          aria-expanded="true"
          aria-haspopup="true"
        />
        {allowClear && (value || typed) && (
          <Button
            size="small"
            shape="circle"
            type="text"
            icon={<CloseCircleFilled />}
            onClick={() => {
              setSelected(null);
              setDropdownOpen(false);
              setTyped("");
              onChange(null);
            }}
          />
        )}
      </div>

      {dropdownOpen && (
        <div
          style={{ height: 320 }}
          ref={dropdownEl}
          className={`max-h-80 z-50 transition-all overflow-y-auto origin-top-left absolute left-0 mt-2 ${
            from ? "w-24" : "w-24"
          } rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
            ${dropdownOpen ? "opacity-100" : "opacity-0"}
          `}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {times.map((time, i) => (
              <a
                onClick={(e) => {
                  setTyped(times[i].format("HH:mm"));
                  onChange(times[i]);
                }}
                className={`cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50 hover:text-gray-900 ${
                  selected === i ? "bg-green-100" : ""
                }`}
                role="menuitem"
              >
                {time.format("HH:mm")} {from && timeString(time, from)}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BetterTimePicker;
