import React, { useContext } from "react";
import { Select, Button } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import OrgContext from "../orgContext";
import useCollectionSubscribe from "../../hooks/useCollectionSubscribe";
import BetterTimePicker from "../BetterTimePicker";
import moment from "moment";

export const depluralise = (word) => {
  if (word?.slice(-1) === "s") {
    return word.slice(0, -1);
  }
  return word;
};

const VolunteerAttendees = ({
  volunteers,
  handleSave,
  handleAddVolunteer,
  handleEditVolunteer,
  handleRemoveVolunteer,
  event,
}) => {
  const { organisation, lexicon } = useContext(OrgContext);
  const [allVolunteers, loading, error] = useCollectionSubscribe({
    path: "Volunteers",
    where: [["managedBy", "==", organisation?._id]],
  });

  const signedUpVolunteers = new Set(
    volunteers.map(({ volunteerId }) => volunteerId)
  );

  const getDefaultStartTime = (vol) => {
    const eventStartTime = vol?.["Start Time"] || event?.start;
    return eventStartTime;
  };

  const getDefaultEndTime = (vol) => {
    const eventEndTime = vol?.["End Time"] || event?.end;
    return eventEndTime;
  };

  return (
    <div className="py-4 px-8">
      <div className="flex items-start justify-between">
        <div>
          <h1 className="text-sm font-medium">
            Add a {depluralise(lexicon.Volunteers) || "volunteer"}
          </h1>
          <Select
            suffixIcon={<SearchOutlined />}
            size="large"
            labelInValue
            placeholder={`Search for a ${
              depluralise(lexicon.Volunteers) || "volunteer"
            }`}
            optionFilterProp="text"
            filterOption
            className="flex-1 max-w-xl w-full"
            showSearch
            onSelect={(item) => {
              handleAddVolunteer(item.value);
            }}
            value={null}
          >
            {allVolunteers
              .sort((a, b) => a.name?.localeCompare?.(b.name))
              .filter(
                (vol) =>
                  !signedUpVolunteers.has(vol.uid) &&
                  !signedUpVolunteers.has(vol._id) &&
                  !signedUpVolunteers.has(vol.volunteerId)
              )
              .map((vol) => (
                <Select.Option value={vol._id} key={vol._id} text={vol.name}>
                  {vol.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <Button type="primary" shape="round" size="large" onClick={handleSave}>
          Save
        </Button>
      </div>
      <h2 className="text-sm mt-4 font-medium">
        Selected {lexicon.Volunteers || "Volunteers"}
      </h2>

      <div className="bg-white border border-gray-200 rounded-md ">
        {volunteers.map((intDoc) => {
          const matchedVolunteer =
            allVolunteers.find(({ _id }) => _id === intDoc.volunteerId) || {};
          const volunteer = {
            ...intDoc,
            ...matchedVolunteer,
          };

          return (
            <div
              key={volunteer._id}
              className="px-4 py-2 border-b border-gray-200 hover:shadow flex justify-between items-center"
            >
              {Array.isArray(volunteer?.name)
                ? volunteer.name[0]
                : volunteer?.name || volunteer?.["Full Name"]?.toString}
              <div className="flex space-x-4">
                <div className="text-xs text-gray-600 flex items-center space-x-2">
                  <div>Start Time</div>
                  <BetterTimePicker
                    minuteStep={5}
                    format="HH:mm"
                    onChange={(time) => {
                      handleEditVolunteer(volunteer, {
                        "Start Time": time.toDate(),
                      });
                    }}
                    // value={moment(getDefaultStartTime(volunteer))}
                    defaultValue={getDefaultStartTime(volunteer)}
                  />
                </div>

                <div className="text-xs text-gray-600 flex items-center space-x-2">
                  <div>End Time</div>
                  <BetterTimePicker
                    minuteStep={5}
                    onChange={(time) => {
                      handleEditVolunteer(volunteer, {
                        "End Time": time.toDate(),
                      });
                    }}
                    defaultValue={getDefaultEndTime(volunteer)}
                  />
                </div>
                <Button
                  shape="round"
                  danger
                  icon={<DeleteOutlined />}
                  type="text"
                  onClick={() => handleRemoveVolunteer(volunteer)}
                >
                  Remove
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VolunteerAttendees;
