import { useEffect, useState } from "react";

const CALL_LIMIT = 20;
const TIME_LIMIT = 2000;

export default function useInfiniteLoopProtection() {
  // count the number of times the function is called
  // throw an error if the function is called more than CALL_LIMIT times in any TIME_LIMIT period
  // this is to prevent infinite loops costing a fortune

  const [timeOfFunctionCalls, setTimeOfFunctionCalls] = useState([]);

  // keep record of most recent CALL_LIMIT calls

  const updateFunctionCalls = () => {
    setTimeOfFunctionCalls((prev) => {
      const newTimeOfFunctionCalls = [...prev];
      newTimeOfFunctionCalls.push(Date.now());
      if (newTimeOfFunctionCalls.length > CALL_LIMIT) {
        newTimeOfFunctionCalls.shift();
      }
      return newTimeOfFunctionCalls;
    });
  };

  useEffect(() => {
    updateFunctionCalls();
  }, []);

  // check if the most recent CALL_LIMIT calls were made in less than TIME_LIMIT milliseconds
  // if so, throw an error

  useEffect(() => {
    if (timeOfFunctionCalls.length === CALL_LIMIT) {
      const timeSinceFirstCall = Date.now() - timeOfFunctionCalls[0];
      if (timeSinceFirstCall < TIME_LIMIT) {
        alert(
          "Infinite loop entered into. Please refresh your page or close this tab."
        );
      }
    }
  }, [timeOfFunctionCalls]);

  return updateFunctionCalls;
}
