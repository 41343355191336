import { useEffect, useState } from "react";
import fire from "../fire";
import useInfiniteLoopProtection from "./useInfiniteLoopProtection";

const db = fire.firestore();

export default function useCollectionSubscribe({
  path,
  where,
  limit,
  orderBy,
  onlyRunIf,
}) {
  const breakerStep = useInfiniteLoopProtection();

  const [collectionData, setCollectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let undefinedArguments = false;
    if (where && Array.isArray(where)) {
      where.forEach((item) => {
        if (item[2] === undefined || item[2] === null) {
          undefinedArguments = true;
        }
      });
    }
    if (path && !undefinedArguments && (onlyRunIf === undefined || onlyRunIf)) {
      try {
        let query = db.collection(path);
        if (where) {
          where.forEach((item) => {
            query = query.where(...item);
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy) {
          query = query.orderBy(...orderBy);
        }

        setLoading(true);
        const schemaUnsubscribe = query.onSnapshot(
          (snap) => {
            setCollectionData(
              snap.docs.map((doc) => ({ ...doc.data(), _id: doc.id }))
            );
            breakerStep();
            setLoading(false);
            setError(false);
          },
          (err) => {
            setError(err);
            setLoading(false);
          }
        );
        return () => schemaUnsubscribe();
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  }, [path, JSON.stringify(where), limit, onlyRunIf]);

  return [collectionData, loading, error];
}
